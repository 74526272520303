<template>
    <div
        @click.stop.prevent="$emit('close')"
        :class="{
            'fixed bg-overlay h-screen': !notpopup,
            'relative mt-4': notpopup,
        }"
        class="flex items-center justify-center top-0 left-0 w-full z-50"
    >
        <div @click="close" v-html="closeIcon" class="absolute right-6 top-2 md:top-6 cursor-pointer"></div>
        <div
            @click.stop.prevent="close"
            :id="notpopup ? '' : 'call-window'"
            :class="{ 'h-full p-16': !notpopup, 'ml-4 mr-4 p-8': notpopup }"
            class="bg-white relative rounded-3xl w-full mx-auto shadow-lg flex items-center justify-center"
        >
            <div class="flex items-center justify-center text-center">
                <div>
                    <div
                        id="success-icon"
                        v-html="successIcon"
                        class="mx-auto flex items-center justify-center relative text-center"
                    ></div>
                    <div class="w-full text-header" style="line-height:1.6em">
                        Apmokėjimas sėkmingas, <br />
                        ačiū kad pirkote!
                    </div>
                    <div>
                        Ne už ilgo gausite el. laišką su atsiėmimo ar pristatymo informacija
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { db } from "./videoMessenger/fire.js";
import { db } from "./videoMessenger/fire.js";
import anime from "animejs/lib/anime.es.js";
import { mapState } from "vuex";
const axios = require("axios");

export default {
    props: {
        notpopup: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    data() {
        return {
            successIcon:
                '<svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M81.9688 49.5625L54.0103 76.25L40.0312 62.9062" stroke="#F9D77A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M61 106.75C86.267 106.75 106.75 86.267 106.75 61C106.75 35.733 86.267 15.25 61 15.25C35.733 15.25 15.25 35.733 15.25 61C15.25 86.267 35.733 106.75 61 106.75Z" stroke="#F9D77A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg> ',
            closeIcon:
                '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
        };
    },
    mounted() {
        this.getUrlVariables();
        this.drawSuccess();
        setTimeout(() => {
            this.removeCart();
        }, 2000);
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            userID: (state) => state.userID,
            appliedDiscount: (state) => state.appliedDiscount,
            cartContent: (state) => state.cartContent,
        }),
    },
    methods: {
        getUrlVariables() {
            const urlString = window.location.search;
            this.updateTransactionInfo(urlString);
        },
        async updateTransactionInfo(data) {
            const result = await axios.get(`${this.$root.baseUrl}/callback/paysera` + data);
            if (result) {
                this.drawSuccess();
            }
        },
        removeCart() {
            db.ref(`userCart/${this.userID}`).remove();
        },
        drawSuccess() {
            anime({
                targets: "#success-icon path",
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: "easeInOutSine",
                duration: 1500,
                delay: function(el, i) {
                    return i * 250;
                },
                loop: false,
            });
        },
        close() {
            this.$router.push("/");
        },
    },
    watch: {
        user() {},
    },
};
</script>

<style lang="scss">
.arrow-up {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
}
#scroll-cart {
    overflow: hidden;
}
#no-popup {
    #scroll-cart {
        max-height: 100%;
        overflow: hidden;
    }
}
</style>
